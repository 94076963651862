import React from "react"
import Header from "./Header";
import ExpressForm from "./ExpressForm";
class Layout extends React.Component {
  render(){
    return (
      <>
      <div className="flex flex-col h-screen">
        <div className="flex flex-col justify-center items-center">
          <Header />
        </div>
        <ExpressForm />
        </div>
      </>
    )
  }
}
export default Layout;